import React from 'react';
import { firebase, auth } from '../firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { HowItWorksSteps } from './steps.component'
import { Typography } from '@material-ui/core';

const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ]
};
export const Auth = () => {
    return (
        <div>
            <Typography variant="h4" align="center">Gratitude Journal</Typography>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth()} />
            <HowItWorksSteps />
        </div>
    );
}