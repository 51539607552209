import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import BrainIcon from '../svgs/brain.svg';
import HabitIcon from '../svgs/habit.svg';
import HappyIcon from '../svgs/happy.svg';
import PlantIcon from '../svgs/plant.svg';
import CoupleIcon from '../svgs/couple.svg';
import { CardMedia } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        margin: 5,
        // minWidth: 275,
    },
    media: {
        backgroundSize: 'contain',
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
});

interface ResearchItem {
    link: string;
    icon: string;
    description: string;
}
const Item = (props: ResearchItem) => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardMedia
                className={classes.media}
                image={props.icon}
                title="Paella dish"
            />
            <CardContent>
                {/* <img src={props.icon} alt={props.description} /> */}
                <Typography variant="h5" component="h2">
                    {props.description}
                </Typography>
            </CardContent>
            <CardActions>
                <a href={props.link} target="_blank" rel="noopener noreferrer">
                    <Button size="small">Learn More</Button>
                </a>
            </CardActions>
        </Card>
    );
}


const mapOfResearchItems: ResearchItem[] = [
    {
        link: 'https://positivepsychology.com/neuroscience-of-gratitude/',
        icon: BrainIcon,
        description: 'Learn about how gratitude effects our brain from a neuroscientists perspective'
    },
    {
        link: 'https://www.happinesslab.fm/2020-mini-season-episodes/reasons-to-be-grateful',
        icon: HabitIcon,
        description: 'Building habits? Forget about finding motivation, find gratitude.'
    },
    {
        link: 'https://positivepsychology.com/benefits-gratitude-research-questions/',
        icon: PlantIcon,
        description: 'Habits help us grow in many ways. Here are 31 ways gratitude can benefit you.'
    },
    {
        link: 'https://greatergood.berkeley.edu/article/item/gratitude_is_for_lovers',
        icon: CoupleIcon,
        description: 'Gratitude should be at the foundation of every relationship.'
    },
    {
        link: 'https://www.health.harvard.edu/healthbeat/giving-thanks-can-make-you-happier',
        icon: HappyIcon,
        description: 'Happiness comes from gratitude.'
    },
]

export const Research = () => {
    return (
        <div>
            {mapOfResearchItems.map(({ link, icon, description }) => <Item link={link} icon={icon} description={description} />)}
            <Typography variant="caption">
                Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
            </Typography>
        </div>
    )
}
