import React, { useEffect, useState } from 'react';
import { Auth, Messages, Footer, Research } from './components'
import { auth } from './firebase';
import { makeStyles } from '@material-ui/core/styles';

interface PageMap {
  [key: string]: JSX.Element
}
const pageMap: PageMap = {
  0: <Messages />,
  1: <Research />,
}
const useStyles = makeStyles({
  appContainer: {
    margin: 'auto',
    width: 'calc( 100vw - 20px)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100vh',


  },

});
function App() {
  const [page, setPage] = useState(0)
  const [authenticated, setAuthenticated] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    auth().onAuthStateChanged(function (user) {
      if (user?.uid) {
        setAuthenticated(true)
      }
    });
  }, [])
  const activePage = pageMap[page];
  return (
    <div className={classes.appContainer}>
      {authenticated
        ?
        <>
          {activePage}
          <Footer page={page} setPage={setPage} />
        </>
        : <Auth />
      }
    </div>
  );
}

export default App;
