import React, { useEffect, useState } from 'react';
import { db, auth, firebase } from '../firebase';
import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
interface Message {
    message: string;
    messageUserStatus: string;
    timestamp: firebase.firestore.Timestamp
}

const useStyles = makeStyles({
    message: {
        marginBottom: 10,
        marginTop: 5,
        padding: 10,
        alignSelf: 'flex-end',
    },
    prompt: {
        alignSelf: 'flex-start',
    }
});

const Message = ({ message, messageUserStatus, timestamp }: Message) => {
    const classes = useStyles();
    return messageUserStatus === "sent"
        ? <Card className={classes.message}>
            <Typography>{message}</Typography>
            <Typography variant="caption">{timestamp.toDate().toLocaleString()}</Typography>
        </Card>
        : <Typography className={classes.prompt} variant="overline">{message}</Typography>
}

const messagesStyles = {
    width: '100%',
    display: 'flex',
    "flex-direction": 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'scroll',
}


export const Messages = () => {
    const [messages, setMessages] = useState<Message[]>([])

    const getMessages = async () => {
        const user = auth().currentUser;
        const uid = user?.uid;
        const snapshot = await db.collection("users").doc(uid).collection("messages").orderBy('timestamp', "asc").get();
        const msgs = snapshot.docs.map(doc => doc.data()) as Message[]
        setMessages(msgs);
    }

    useEffect(() => {
        getMessages();
    })

    return (
        <div style={messagesStyles}>
            {messages.map((data, index) => <Message key={index} {...data} />)}
        </div>
    )
}