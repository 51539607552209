import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

firebase.initializeApp({
    apiKey: "AIzaSyBHKrR_PMNk8l9Zu8jtDl9m6NmoVc5wAGw",
    authDomain: "gratitude-35ec1.firebaseapp.com",
    databaseURL: "https://gratitude-35ec1.firebaseio.com",
    projectId: "gratitude-35ec1",
    storageBucket: "gratitude-35ec1.appspot.com",
    messagingSenderId: "656101039379",
    appId: "1:656101039379:web:757238f28c44c96fc18ee6",
    measurementId: "G-PMNMH5K7FD"
});

const db = firebase.firestore();
db.enablePersistence()
    .catch(function (err) {
        if (err.code == 'failed-precondition') {
            // Multiple tabs open, persistence can only be enabled
            // in one tab at a a time.
            // ...
        } else if (err.code == 'unimplemented') {
            // The current browser does not support all of the
            // features required to enable persistence
            // ...
        }
    });

const auth = firebase.auth;
// const user = firebase.auth().currentUser;
// auth.currentUser
export { auth, db, firebase };

