import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import HappyIcon from '../svgs/hiw_happy.svg';
import MessageIcon from '../svgs/hiw_message.svg';
import MobileIcon from '../svgs/hiw_mobile.svg';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);

function getSteps() {
    return ['We send a gratitude prompt daily', 'Reply, Responses are saved to a journal', 'Feel the gratitude'];
}

function getStepContent(stepIndex: number) {
    switch (stepIndex) {
        case 0:
            return MessageIcon;
        case 1:
            return MobileIcon;
        case 2:
            return HappyIcon;
        default:
            return 'Unknown stepIndex';
    }
}

export function HowItWorksSteps() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    console.log('running')

    useEffect(() => {
        const i = setInterval(() => {

            setActiveStep(c => {
                if (c < (steps.length - 1)) {
                    return c + 1
                } else {
                    return 0
                }
            })

        }, 4000);
        return () => clearInterval(i)
    }, [])
    return (
        <div className={classes.root}>
            <Typography variant="h4" align="center">
                How it Works
            </Typography>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <img src={getStepContent(activeStep)} style={{ width: '100%', height: '300px' }} alt={steps[activeStep]} />
        </div>
    );
}
