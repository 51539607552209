import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import ChatTwoToneIcon from '@material-ui/icons/ChatTwoTone';
import MoreReadingIcon from '@material-ui/icons/ImportContactsTwoTone';
import { makeStyles } from '@material-ui/core/styles';
interface Props {
    page: number;
    setPage: (num: number) => void;
}
const useStyles = makeStyles({
    footer: {
        position: "sticky",
        bottom: 0,
        width: '100%'
    },

});
export const Footer = ({ page, setPage }: Props) => {
    const { footer } = useStyles();
    return (
        <BottomNavigation
            className={footer}
            value={page}
            onChange={(event, newValue) => {
                setPage(newValue);
            }}
            showLabels
        >
            <BottomNavigationAction label="Messages" icon={<ChatTwoToneIcon />} />
            <BottomNavigationAction label="Research" icon={<MoreReadingIcon />} />
        </BottomNavigation>
    )
}